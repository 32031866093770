@import url('https://fonts.googleapis.com/css2?family=Just+Another+Hand&display=swap');
@import '~js-datepicker/dist/datepicker.min.css';

body {
  overflow: hidden;
}

.App {
  display: flex;
  flex-direction: column;  
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.AddedToast {
  display: flex;
  position: fixed;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  top: 2em;
  width: 80%;
  max-width: 200px;
  color: #000;
  background-color: #23ADA5;
  border-radius: 6px;
  border-style: solid;
  border-width: 0.125rem;
  border-color: #000;
  line-height: 1.1em;
  padding: 0.5rem;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
  z-index: 10;
}

.AddedToast-initial {
  display: none;
}

.AddedToast-show {
  animation: showAddedToast .25s forwards;
}

.AddedToast-hide {
  animation: hideAddedToast .25s forwards;
}

@keyframes showAddedToast {
  from { transform: scale(0); }  
  to { transform: scale(1); }
}

@keyframes hideAddedToast {
  from { transform: scale(1); }  
  to { transform: scale(0); }
}

.AddedToast p {
  margin: 0;
  margin-left: 10px;
  margin-right: 10px;
}

.App-grey {
  background-color: #C2C2C2;
}

.App-white {
  background-color: #FFF;
}

.Content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 6em;
  top: 2em;
  right: 2em;
  left: 2em;
}

.Form {
  margin: 1.5em;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

}

.HomePage {
  justify-content: space-between;
}

.TrackPage {
  justify-content: space-between;
}

.App-logo {
  height: calc(25%);
  pointer-events: none;
}

.Button {
  font-family: 'Just Another Hand', cursive;
  font-size: medium;
  color: #000000;
  display: block;
  padding: 12px 25px;
  margin: 6px auto;
  border-radius: 6px;
  border: 1px solid #000000;
  width: 100%;
}

.ButtonGroup {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Navbar {
  display: flex;
  align-content: flex-end;
  column-gap: 0.5rem;
  position: fixed;
  right: 2em;
  left: 2em;
  bottom: 2em;
}

.NavbarButton {
  background-color: #23ADA5;
  border: 1px solid black;
  border-radius: 6px;
  margin: 0px;
  padding: 0px;
  width: 3.5em;
  height: 3.5em;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  animation-name: shrinkButton;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes shrinkButton {
  from {flex-grow: 1;}
  to {flex-grow: 0;}
}

.NavbarButton.active {
  animation-name: growButton;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes growButton {
  from {flex-grow: 0;}
  to {flex-grow: 1;}
}

.Button-white {
  background-color: #D9D9D9;
  color: #000000;
}

.Button-red {
  background-color: #E0554D;
  color: #fff;
}

.Button-green {
  background-color: #23ADA5;
  color: #fff;
}

.Bike-illustration {
  display: block;
  margin: 30px;
}

.Bike-illustration-small {
  width: 8em;
  display: block;
  margin: 10px;
}

.Bike-illustration-medium {
  width: 12em;
  display: block;
  margin: 40px;
}

.ConfirmDeleteModalBackground {
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 15;
}

.ConfirmDeleteModal > p.title {
  font-family: 'Just Another Hand', cursive;
  font-size: 2em;
  margin: 0.5rem 0.3rem;
}

.ConfirmDeleteModal > p.subtitle {
  margin: 1rem 0.3rem;
}

.ConfirmDeleteModal > div {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
}

.ConfirmDeleteModal {
  z-index: 20;
  padding: 10px;
  width: 70%;
  max-width: 280px;
  border: 2px solid black;
  border-radius: 6px;
  background-color: #C2C2C2;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}

.DeleteConfirmYesBtn {
  background-color: #23ADA5;
  border-radius: 6px;
  padding: 0.5em 1em;
  width: 100%;
  font-family: 'Just Another Hand', cursive;
  font-size: 1.25em;
}

.DeleteConfirmNoBtn {
  background-color: #E0554D;
  border-radius: 6px;
  padding: 0.5em 1em;
  width: 100%;
  font-family: 'Just Another Hand', cursive;
  font-size: 1.25em;
}

.DeleteButton {
  align-items: center;
  justify-content: center;
  padding-right: 10px;
  padding-left: 10px;
  height: 2em;
}

.DeleteButton-show {
  display: flex;
}

.DeleteButton-hide {
  display: none;
}

.DeleteButton > .Icon {
  padding-bottom: 8px;
}

.GraphSection {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 40%;
}

.GraphParentContainer {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 2px solid black;
}

.HistoryScrollView {
  display: flex;
  right: 0;
  left: 0;
  height: 100%;
  position: absolute;
  overflow-y: scroll;
  padding-right: 1em;
}

.HistoryScrollView::-webkit-scrollbar-thumb {
  background: #23ADA5;
}

.HistoryContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.HistoryItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.HistoryItem p {
  font-family: 'Just Another Hand', cursive;
  font-size: 1.5em;
  margin: 10px 0px;
}

.HistoryItemContent {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.HistoryItemContent > div:nth-child(2) {
  display: flex;
  align-items: center;
}

.HistoryItemContent > div:first-of-type {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}

.HistoryPage {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.Marker {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: 8px;
  border-radius: 6px;
  color: #23ADA5;
  background-color: #23ADA5;
}

.Marker::before {
  content: '';
  width: 4px;
  height: 3em;
  margin: 4px;
  background-color: #23ADA5;
  position: absolute;
  transform: translateY(-3em);
}


.Icon {
  color: #fff;
  padding: 3px;
}

.InputField {
  display: flex;
  margin-bottom: 12px;
  width: 100%;
}

.Input-label {
  display: block;
  font-family: 'Just Another Hand', cursive;
  font-size: 16px;
  margin: auto;
  width: 100%;
}

.Input {
  display: block;
  border: 1px solid #000000;
  padding: 8px;
  border-radius: 6px;
  width: 100%;
  background-color: transparent;
}

.LinkText {
  font-family: 'Just Another Hand', cursive;
  text-decoration: none;
  color: #23ADA5;
  padding: 12px;
}

.Odometer {
  width: 100%;
}

.OdometerInput {
  width: 100%;
}

.OdometerInputField {
  border: 2px solid #000000;
  border-radius: 6px;
  width: calc(100% - 52px);
  font-family: 'Just Another Hand', cursive;
  font-size: 6em;
  text-align: right;
  margin: 0px;
  padding: 6px 24px;
  background-color: transparent;
}

.OdometerInputField-underline {
  position: relative;
  bottom: 1.5em;
  width: 86%;
  height: 1px;
  margin: 0px auto;
  background-color: #000000;
  border: none;
}

.OdomemeterText {
  font-family: 'Just Another Hand', cursive;
  font-size: 1.5em;
  margin: 0px;
  margin-right: 12px;
}

.OdometerSubscript {
  font-family: 'Just Another Hand', cursive;
  font-size: 1.5em;
  text-align: right;
  margin: 6px 0px 0px 0px;
}

.OdometerDisplayText {
  font-family: 'Just Another Hand', cursive;
  font-size: 6em;
  text-align: right;
  margin: 0px;
  padding: 0.1em;
  vertical-align: middle;
  height: 0.9em;
  line-height: 1.15em;
}

.OdometerSelectSection {
  display: flex;
  align-items: center;
}

.OdometerDisplay {
  border: 2px solid #000000;
  border-radius: 6px;
  padding: 0.5em;
}

.DateSelectButton {
  font-family: 'Just Another Hand', cursive;
  font-size: medium;
  background-color: #23ADA5;
  color: #fff;
  display: block;
  border-radius: 6px;
  border: 1px solid #000000;
  width: 10em;
  padding: 6px;
}

.PeriodSelect {
  font-family: 'Just Another Hand', cursive;
  font-size: large;
  background-color: #23ADA5;
  color: #fff;
  display: block;
  border-radius: 6px;
  border: 1px solid #000000;
  width: 10em;
  padding: 6px;
  text-align: center;
}

.PeriodSelect option {
  padding: 30px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
}
input[type=number] {
  -moz-appearance:textfield;
}

@media screen and (min-width: 480px){
  .App {
    display: flex;
  }
  .Content {
    display: flex;
    max-width: 350px;
    margin: auto;
  }
  .Navbar {
    max-width: 350px;
    margin: auto;
  }
}

/* Date Picker */
.qs-datepicker-container {
  margin-top: 0.25em;
  border-color: #000;
  border-radius: 6px;
}
.qs-controls {
  background-color: #23ADA5;
}
.qs-month-year {
  color: #000;
}
.qs-active {
  background: #23ADA580;
}
.qs-day {
  font-weight: normal;
}

